<template>
    <li class="settings__selected-user">
        <div class="settings__selected-wrap">
            <div class="settings__user-wrap">
                <router-link :to="user.link" target="_blank">
                    <avatar :src="user.image_zoom_out"/>
                </router-link>
                <div class="settings__user-info">
                    <router-link :to="user.link" target="_blank" class="settings__user-title">{{user.short_name}}</router-link>
                    <div v-if="!isEditing" class="settings__user-merit">
                        <span v-if="!merit.length" class="settings__user-merit">Расскажите о заслугах сотрудника</span>
                        <span v-else class="settings__user-desc">“{{merit}}”</span>
                        <div class="settings__user-about">
                            <span class="settings__user-position">{{user.positions}}</span>
                            <span
                                v-for="struct in user.structure"
                                class="settings__user-structs"
                            >
                                {{struct.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="settings__user-controls">
                <button
                    class="settings__user-btn settings__user-delete"
                    title="Удалить"
                    @click="onRemove(user)"
                />
                <button
                    v-if="!isEditing"
                    @click="isEditing = true"
                    class="settings__user-btn settings__user-edit"
                    title="Редактировать"
                />
            </div>
        </div>
        <div v-if="isEditing" class="settings__selected-edit">
            <textarea-autosize
                class="settings__selected-textarea"
                :maxlength="100"
                v-model.trim="merit"
            />
            <div class="settings__selected-meta">
                <div class="settings__selected-btns">
                    <button
                        :disabled="!merit.length"
                        @click="saveMerit(user.id, merit)"
                        class="v-btn"
                    >
                        Готово
                    </button>
                    <button class="v-btn v-btn--transparent" @click="cancelEdit">Отменить</button>
                </div>
                <span class="settings__selected-count">{{merit.length}}/100</span>
            </div>
        </div>
    </li>
</template>

<script>
    export default {
        name: "SelectedUser",
        components: {
            Avatar: () => import('@/components/Avatar'),
        },
        data() {
            return {
                isEditing: false,
                merit: this.user.merit || '',
                oldMerit: this.user.merit || ''
            }
        },
        methods: {
            saveMerit(userId,merit) {
                this.oldMerit = this.merit;
                this.onSave(userId, merit);
                this.isEditing = false;
            },
            cancelEdit() {
                this.merit = this.oldMerit;
                this.isEditing = false;
            }
        },
        props: {
            user: {
                type: Object,
                required: true
            },
            onSave: {
                type: Function,
                required: false
            },
            onRemove: {
                type: Function,
                required: false
            },
        },
    }
</script>

<style scoped>

</style>
